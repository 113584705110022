<template>
  <div>
    <h4>NOTA DE SALIDA {{ output_id }}</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="card mb-3">
          <div class="card-body">
            <FastCode
              ref="fastCode"
              :money_code="reg.money_code"
              @preoutput="$refs.trLoading.state(true)"
              @submitted="$refs.tr.gl()"
              :output_id="output_id"
            >
            </FastCode>
          </div>
        </div>

        <Form ref="formCreate" :disabled="true"></Form>
        <button
          v-show="!readonly"
          class="btn btn-primary"
          @click="$refs.dbefTra.show()"
        >
          Transferir
        </button>
      </div>
      <div class="col">
        <app-table-registers :getList="getList" ref="tr">
          <template slot="tl">
            <div class="col">
              <button
                v-show="!readonly"
                class="btn btn-primary"
                @click="
                  $refs.dNewDet.show();
                  $refs.newDet.reset();
                "
              >
                <i class="fa fa-plus"></i> Detalle
              </button>
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th></th>
                <th>PRODUCTO</th>
                <th>CANTIDAD</th>
                <th>PRECIO UNITARIO</th>
                <th>SUB TOTAL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <app-tr-loading ref="trLoading"></app-tr-loading>
              <tr v-for="l in list.data" :key="l.id">
                <td>DS-{{ l.id }}</td>
                <td>{{ l.product_name }}</td>
                <td>{{ l.quantity }}</td>
                <td>
                  <app-span-money
                    :quantity="l.unit_price"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td>
                  <app-span-money
                    :quantity="l.unit_price * l.quantity"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      v-show="!readonly"
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.dDelDet.show();
                        $refs.dDelDet.val = l.id;
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="dNewDet">
      <FormDetail
        ref="newDet"
        :output_id="output_id"
        @submitted="
          $refs.dNewDet.hide();
          $refs.tr.gl();
          $refs.newDet.reset();
        "
        :store_id="$refs.formCreate ? $refs.formCreate.reg.store_id : undefined"
      ></FormDetail>
    </app-modal-basic>

    <app-modal-yn
      ref="dDelDet"
      @yes="deleteDetail($refs.dDelDet.val)"
      @no="$refs.dDelDet.hide()"
    >
      <p v-if="$refs.dDelDet && $refs.dDelDet.val">
        Seguro de eliminar el registro
        <b>DS-{{ $refs.dDelDet.val }}</b
        >?
      </p>
    </app-modal-yn>

    <app-modal-yn
      ref="dbefTra"
      @yes="letsTransfer()"
      @no="$refs.dbefTra.hide()"
    >
      <p>Seguro de transferir.</p>
    </app-modal-yn>
  </div>
</template>

<script>
import { LogisticService } from "../service";
import FormDetail from "./FormDetails";
import Form from "./FormCreate";
import FastCode from "./FastCode";

export default {
  components: {
    FormDetail,
    Form,
    FastCode
  },
  props: {
    output_id: {}
  },
  data: () => ({
    list: {},
    readonly: false,
    reg: {}
  }),
  created() {
    this.loadValues();
  },
  computed: {
    // readonly() {
    //   return this.$refs.formCreate ? this.$refs.formCreate.reg.readonly : false;
    // }
  },
  methods: {
    /**@private */
    loadValues() {
      LogisticService.getOutput(this.output_id).then(res => {
        this.$refs.formCreate.loadReg(res);
        this.reg = res;
        this.readonly = res.readonly;
        this.$refs.tr.gl();
      });
    },
    letsTransfer() {
      LogisticService.transferOutput(this.output_id).then(() => {
        // alert("gaaaaaa");
        this.loadValues();
        this.$refs.dbefTra.hide();
      });
    },
    deleteDetail(id) {
      LogisticService.deleteOutputDetail(id).then(() => {
        this.$refs.dDelDet.hide();
        this.$refs.tr.gl();
      });
    },
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getOutputDetails({
          output_id: this.output_id,
          page,
          search
        }).then(res => {
          this.list = res;
          this.$refs.trLoading.state(false);
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
