<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Tipo de Salida</label>
      <SelectType :disabled="disabled" v-model="reg.type_id"></SelectType>
      <app-small-form-errors :errors="errors.type_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Almacen Origen</label>
      <SelectStore
        :disabled="disabled"
        ref="selSto1"
        v-model="reg.store_id"
      ></SelectStore>
      <app-small-form-errors :errors="errors.store_id"></app-small-form-errors>
    </div>
    <div class="form-group" v-show="reg.type_id == 2">
      <label for>Almacen destino</label>
      <SelectStore
        :disabled="disabled"
        ref="selSto2"
        v-model="reg.target_store_id"
      ></SelectStore>
      <app-small-form-errors
        :errors="errors.target_store_id"
      ></app-small-form-errors>
    </div>
    <app-button-submit v-show="!disabled" :disabled="errors"
      >Continuar</app-button-submit
    >
  </form>
</template>

<script>
import SelectType from "./SelectType";
import SelectStore from "../stores/Select";
import validate from "validate.js";
import { LogisticService } from "../service";

const formRules = function(reg) {
  var targetRules = {};
  if (reg.type_id == 2) {
    targetRules = {
      presence: { message: "Requerido" },
      exclusion: {
        within: [reg.store_id],
        message: "no puedes usar ese destino"
      }
    };
  }
  return {
    type_id: { presence: { message: "Requerido" } },
    store_id: { presence: { message: "Requerido" } },
    target_store_id: targetRules
    // money_code: { presence: { message: "Requerido" } }
  };
};

export default {
  props: {
    disabled: {
      default: false
    }
  },
  components: { SelectType, SelectStore },
  data: () => ({
    reg: {}
  }),
  methods: {
    loadReg(reg) {
      this.reg = reg;
      this.$refs.selSto1.setValueFromId(reg.store_id);
      this.$refs.selSto2.setValueFromId(reg.target_store_id);
    },
    reset() {
      this.reg = {};
    },
    save() {
      LogisticService.saveOutput(this.reg).then(res => {
        this.$emit("submitted", res);
      });
    }
  },
  computed: {
    errors() {
      let err = validate(this.reg, formRules(this.reg), {
        fullMessages: false
      });
      return err ? err : false;
    }
  }
};
</script>

<style>
</style>
