<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct ref="selPro" :store_id="store_id" v-model="reg.product_id"></SelectProduct>
      <app-small-form-errors :errors="errors.product_id"></app-small-form-errors>
    </div>
    <div class="form-group">
      <label for>Cantidad</label>
      <app-input-number v-model="reg.quantity"></app-input-number>
      <app-small-form-errors :errors="errors.quantity"></app-small-form-errors>
    </div>
    <app-button-submit></app-button-submit>
    <div class="mt-2 alert alert-danger" v-show="submitError">
      Error al registrar detalle:
      <span v-if="submitError">{{ submitError.message }}</span>
    </div>
  </form>
</template>

<script>
import SelectProduct from "../../store-module/products/Select";
import validate from "validate.js";
import { LogisticService } from "../service";

const formRules = {
  product_id: { presence: { message: "Requerido" } },
  quantity: { presence: { message: "Requerido" } }
};

export default {
  components: {
    SelectProduct
  },
  props: {
    output_id: {},
    store_id: {}
  },
  data: () => ({
    reg: {},
    submitError: false
  }),
  computed: {
    errors() {
      let err = validate(this.reg, formRules, { fullMessages: false });
      return err ? err : false;
    }
  },
  methods: {
    reset() {
      this.submitError = false;
      this.reg = {};
      this.$refs.selPro.reset();
    },
    save() {
      this.submitError = false;
      LogisticService.saveOutputDetail({
        ...this.reg,
        output_id: this.output_id
      }).then(
        res => this.$emit("submitted", res),
        err => {
          this.submitError = err.response.data;
        }
      );
    }
  }
};
</script>

<style>
</style>
